import React from 'react';

import Layout from '../components/layout';
import { H1, H4, P } from '../components/typography';
import SEO from '../components/seo';
import { ImagePartyGirl } from '../components/image';
import { ImageConcertBackground } from '../components/image2';
import {ImageCardboardApp} from '../components/image3';
import LinkToAppStore from '../images/app-comingsoon.svg'

const IndexPage = () => (
    <Layout>
      <SEO title="Privacy Policy"/>
      <section className="section" style={{ paddingTop: `7rem`}}>
        <div className="container">
          <div className="content-body">
            <H1>
              Privacy Policy
            </H1>
            <P>
              Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den Cookie erzeugten Informationen über Ihre Benutzung diese Website (einschliesslich Ihrer IP-Adresse) wird an einen Server von Google in den USA übertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google in Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.
            </P>
            <P>Soweit Sie Ihre Einwilligung erklärt haben, wird auf dieser Website Google Analytics eingesetzt, ein Webanalysedienst der Google Inc. („Google“). Die Nutzung umfasst die Betriebsart „Universal Analytics“. Hierdurch ist es möglich, Daten, Sitzungen und Interaktionen über mehrere Geräte hinweg einer pseudonymen User-ID zuzuordnen und so die Aktivitäten eines Nutzers geräteübergreifend zu analysieren. Dieser Datenschutzhinweis wird zur Verfügung gestellt von <a
                href="https://www.intersoft-consulting.de/" target="_blank">www.intersoft-consulting.de</a>.</P>
          </div>
        </div>
      </section>
    </Layout>
);

export default IndexPage;
